import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';

export const routes: Routes = [
  {
    path: 'ubo',
    loadChildren: () => import('./ubo/ubo.module').then(m => m.UboModule),
  },
  {
    path: 'identity-verification',
    loadChildren: () => import('./identity-verification/identity-verification.module').then(m => m.IdentityVerificationModule),
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ]
})
export class AppRoutingModule {
}
